import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import ReactMarkdown from 'react-markdown'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/about-us.css'

const WhatWereAbout = ({ data }) => {
  const storyImage = getImage(data.markdownRemark.frontmatter.storyPhoto.photo)
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <h2>Our Mission</h2>
        <div className="about-us-row">
          {data.markdownRemark.frontmatter.video && ((data.markdownRemark.frontmatter.video.includes("youtube") || data.markdownRemark.frontmatter.video.includes("youtu.be"))
            ? <iframe src={data.markdownRemark.frontmatter.video} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video"></iframe>
            : <video src={data.markdownRemark.frontmatter.video} controls />
          )}
          <div className="content">
            <ReactMarkdown children={data.markdownRemark.frontmatter.mission} />
          </div>
        </div>
        <section className="center-text">
          <h2>Our Vision</h2>
          <ReactMarkdown children={data.markdownRemark.frontmatter.vision} />
        </section>
        <h2>Our Story</h2>
        <div class="flex-row flex-mobile reverse">
          {data.markdownRemark.frontmatter.storyPhoto ? (
            <div className="equal">
              <GatsbyImage image={storyImage} alt={data.markdownRemark.frontmatter.storyPhoto.altText} />
            </div>
          ) : null}
          <div className="equal">
            <ReactMarkdown children={data.markdownRemark.frontmatter.story} />
          </div>
        </div>
        <h2>Our Values</h2>
        <ReactMarkdown children={data.markdownRemark.frontmatter.values} />
        <h2>Our Principles</h2>
        <ReactMarkdown children={data.markdownRemark.frontmatter.principles} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query WhatWereAboutQuery {
  markdownRemark(frontmatter: {title: {eq: "What We're About"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      mission
      vision
      story
      values
      principles
      video
      storyPhoto {
        photo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 0.75
            )
          }
        }
        altText
      }
    }
  }
}
`

export default WhatWereAbout